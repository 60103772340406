<template>
    <div class="outer-box vertical-center-transform">
        <div class="posRel">
            <div class="col-xs-12 col-md-6">
                <div class="col-xs-12 login-box">
                    <div class="col-xs-12 text-center">
                        <!-- <img class="pickl-logo" src="/img/pickl-logo.png"/> -->
                        <h2>Welcome to PICKL!</h2>
                        <h4 style="margin-top:20px;">Hey Brands! Create your free account here:</h4>
                        <h4>Everyone else: Please download PICKL at the Apple/Google App store.</h4>
                    </div>
                    <RegisterForm v-if="!leadCreated && !accountAlreadyExists "  :busy="busy" @submit="registerBrand" :errorMessage="errorMessage" :brandRefer="brandRefer" :accessInvitaion="accessInvitaion"/>
                    <Password v-if="accountAlreadyExists && !leadCreated" :busy="busy" :responseDetails="UserRegisterResponse" :errorMessage="errorMessage" @submit="submitPassword" />
                    <div v-if="accountCreated">
                      <h2>Welcome To  <span class="green-text">PICKL</span></h2>
                      <h3 style="text-align:justify;">Thank you for the interest, One of our representative will be keep in touch with you within 24 hours.</h3><br/><br/>
                      <h4 class="pull-right" ><router-link :to="'/brand/login'">Click Here</router-link> to Login</h4>
                    </div>
                    <EmailMobileVerification v-if="leadCreated && !accountCreated" :busy="busy" @submit="verifyEmailMobile" :user="user" :errorMessage="errorMessage" @sendVerificationCodesAgain="sendVerificationCodesAgain" />
                    <h3 v-if="!accountCreated" style="text-align:left; font-size:25px;"><a class="btn-sm-green" @click="pushToLoginPage" style="cursor:pointer;text-decoration:none;">Login</a></h3>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 video-out vertical-center-transform">
                <div class="video-box" style="text:align:center;">

                    <!-- <h3>Manage Pickl, Picklrs Easily</h3> -->
                    <!-- <TutorialVideos></TutorialVideos> -->
                    <!-- <h3>Get Started with <span class="green-text">PICKL</span></h3> -->
                    <ul style="margin-left:-15px;text-align:center;">

                    </ul><br/>
                    <img class="pickl-logo" src="/img/brand_new_feature.png" style="width:100%;height:auto;margin:auto;display:block;"/>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>
<script>
import TutorialVideos from '@/components/common/TutorialVideos.vue'
import RegisterForm from '@/components/Brand/RegisterForm.vue'
import EmailMobileVerification from '@/components/Brand/EmailMobileVerification.vue'
import Password from '@/components/Brand/Password.vue'

export default {
  name: 'login',
  components: {
    TutorialVideos,
    RegisterForm,
    EmailMobileVerification,
    Password
  },
  data () {
    return {
      accountCreated: false,
      leadCreated: false,
      user: {},
      errorMessage: '',
      busy: false,
      accountAlreadyExists: false,
      UserRegisterResponse: [],
      createdLeadData: [],
      callRegisterLeadFunction: false,
      currentPassword: '',
      brandName: '',
      brandRefer: false,
      accessInvitaion: {}
    }
  },
  mounted () {
    if (typeof this.$route.query.refer !== 'undefined') {
      this.getInvitationDetails()
    }
  },
  methods: {
    getInvitationDetails () {
      this.$store.dispatch('verifyInvitaionSecret', { secret: this.$route.query.refer })
        .then(
          (response) => {
            if (response.body.data === null) {
              this.$notify({ type: 'error', text: 'Link is invalid or expired!' })
              this.$router.push('/brand/login')
            } else {
              this.brandRefer = true
              this.accessInvitaion = response.body.data
              if (response.body.data.userExists === true) {
                this.$router.push('/brand/login?refer=' + encodeURIComponent(this.$route.query.refer))
              }
            }
          },
          (response) => {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },
    pushToLoginPage () {
      if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
        this.$router.push('/brand/login?redirect=' + encodeURIComponent(this.$route.query.redirect))
      } else {
        this.$router.push('/brand/login')
      }
    },
    verifyEmailMobile (codes) {
      this.user.verificationCodes = codes
      let data = { lead_id: this.createdLeadData.id, sms_code: codes.smsCode, email_code: codes.emailCode, password: this.currentPassword }
      if (this.brandRefer === true) {
        data.invitation_id = this.accessInvitaion.id
      }
      this.$http
        .post('sms-email-verification', data, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          function (response) {
            this.$store.commit('setTokenToLocalStorage', response.body)
            this.getUserByToken()
          },
          function (response) {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },

    getUserByToken () {
      this.$store.dispatch('getUserData', 'brand')
        .then(
          (response) => {
            this.$store.commit('setUserDataToLocalStorage', response.body)
            if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
              this.$router.push(this.$route.query.redirect)
            } else {
              if (response.body.DataAccessSubscriptionId === null && response.body.dashboardDataAccess === 0) {
                this.$router.push('/brand/data-access-subscription')
              } else {
                this.$router.push('/brand/dashboard')
              }
            }
          },
          (response) => {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },

    sendVerificationCodesAgain () {
      this.$http
        .post('resend-verification-codes', this.createdLeadData, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          (response) => {
            this.$notify({ type: 'success', text: response.body.message })
          },
          (response) => {
            this.busy = false
          }
        )
    },

    sendEmailVerificationLink () {
      this.$http
        .post('email/resend')
        .then(
          (response) => {
            this.accountCreated = true
          },
          (response) => {
            this.busy = false
          }
        )
    },

    registerBrand (user) {
      this.busy = true
      this.errorMessage = ''
      this.brandName = user.name
      if (this.brandRefer === true) {
        this.accessInvitaionSecret = this.accessInvitaion.secret
      }
      this.$http
        .post('register', user, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          (response) => {
            this.user = response.body.data
            this.leadCreated = true
            this.createdLeadData = response.body.data
            this.currentPassword = user.password
          },
          (response) => {
            if (response.body.code === 'user_exists') {
              this.accountAlreadyExists = true
            }
            this.UserRegisterResponse = response.body
            this.busy = false
            this.errorMessage = response.body.message
            this.currentPassword = user.password
          }
        )
    },
    submitPassword (password) {
      let details = {
        email: this.UserRegisterResponse.user_details.email,
        mobile_number: this.UserRegisterResponse.user_details.mobile_number,
        password: password
      }
      this.$http
        .post('verify-email-password', details, {
          headers: {
            'user-type': 'brand'
          }
        })
        .then(
          (response) => {
            this.user = response.body.data
            this.callRegisterLeadFunction = true
            this.currentPassword = password
          },
          (response) => {
            this.accountAlreadyExists = true
            this.busy = false
            this.errorMessage = response.body.message
          }
        )
    }
  },
  watch: {
    callRegisterLeadFunction () {
      if (this.callRegisterLeadFunction === true) {
        let userData = {
          email: this.user.email,
          name: this.brandName,
          user_name: this.user.name,
          mobile_number: this.user.mobile_number,
          password: this.currentPassword,
          existing_user: true
        }
        this.registerBrand(userData)
      }
    }
  }
}
</script>

<style scoped>
    .outer-box {
        margin: 0 auto;
        max-width: 1366px;
        width: 100%;
        background-image: url("/img/background-portrait.png");
        background-size: 50% 100%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    .pickl-logo {
        margin-bottom: 30px;
        width: 120px;
        height: 120px;
        border-radius: 7px;
    }

    .login-box {
        margin: 5% auto 0;
        padding: 10px 90px 20px;
    }

    .video-out {
        padding-left: 0;
        /* min-height: 100vh; */
        left: auto;
        right: 0;
    }

    .video-box {
        margin: 0 auto;
        /* margin: 0 0 20px; */
        padding: 25px 10px 15px;
    }

    .video-box h3 {
        font-weight: 700;
        text-align: center;
        font-size: 44px;
        color: black;
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 1199px) {
        .login-box {
            padding: 10px 35px 20px;
        }
    }

    @media only screen and (max-width: 991px) {
        .outer-box {
            background-size: 100% 100vh;
            background-repeat: repeat-y;
            position: static;
            transform: none;
        }

        .video-out {
            padding-left: 15px;
            min-height: auto;
            position: static;
            transform: none;
        }

        .video-box {
            margin-bottom: 20px;
        }
    }

    @media only screen and (max-width: 575px) {
        .login-box {
            padding: 15px;
        }

        .video-box h3 {
            font-size: 36px;
            padding: 15px;
        }
    }

    @media only screen and (max-height: 660px) {
        .outer-box {
            position: static;
            transform: none;
        }
    }
</style>
