<template>
    <div>
        <h2 class="register-header">You Are Already Registered with  <span class="green-text">PICKL</span></h2>
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <input type="password" v-model="password" placeholder="Password" autocomplete="current-password" required />
            <input :disabled="busy" type="submit" value="Continue" class="btn-lg-green" />
        </form>
    </div>
</template>
<script>
export default {
  name: 'Password',
  props: {
    errorMessage: String,
    busy: Boolean
  },
  data () {
    return {
      password: ''
    }
  },
  methods: {
    onSubmit: function () {
      this.$emit('submit', this.password)
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 51px;
  line-height: 72px;
  margin-bottom: 20px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
</style>
