var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "two-step" }, [
    _c("h2", [_vm._v("Two-factor authentication")]),
    _vm._v(
      "\n    Verification code has been sent to mobile & email. Enter the code.\n    "
    ),
    _c("label", {
      staticClass: "error-msg",
      domProps: { innerHTML: _vm._s(_vm.errorMessage) }
    }),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          [
            _c("label", { staticClass: "verification-label" }, [
              _vm._v("Enter Mobile Verification Code")
            ]),
            _c("br"),
            _vm._l(6, function(n) {
              return _c("input", {
                key: n,
                ref: "sms-verification-ref",
                refInFor: true,
                attrs: { type: "tel", maxlength: "1", autofocus: 1 == n },
                on: {
                  keydown: function($event) {
                    return _vm.handleSmsKeyPress(n - 1, $event)
                  }
                }
              })
            })
          ],
          2
        ),
        _c("div", { staticClass: "resend-text" }, [
          _vm._v("Didn't get the verification codes? "),
          _c(
            "a",
            {
              staticClass: "purple-link",
              attrs: { href: "javascript:;" },
              on: { click: _vm.sendVerificationCodesAgain }
            },
            [_vm._v("Send Again")]
          )
        ]),
        _c("input", {
          staticClass: "btn-lg-green",
          attrs: { type: "submit", value: "CONTINUE" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }