<template>
    <div>
        <!-- <h3 class="register-header">Register To  <span class="green-text">PICKL</span></h3> -->
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
          <h4 v-if="brandRefer" style="text-align:center;" >You have been invited by <strong class="green-text">{{accessInvitaion.brand.name}}</strong> to join pickl</h4>
            <input type="text" v-model="brand.user_name" placeholder="Full Name" autocomplete="name" required />
            <input type="text" v-model="brand.name" placeholder="Brand Name" autocomplete="name" required :disabled="brandRefer" />
            <input type="email" v-model="brand.email" placeholder="Email" autocomplete="email" required :disabled="brandRefer"/>
            <select v-model="brand.iso_code" class="country-code-field">
              <option v-for="country in countries" :value="country.iso_code" :key="country.iso_code">{{country.name}}({{ country.isd_code }})</option>
            </select>
            <the-mask class="phone-field" mask="### ###-####" type="tel" autocomplete="mobile" placeholder="Mobile Number" v-model="brand.mobile_number" name="mobile" v-validate="{ required: true, regex: /^\d{3} ?\d{3}-?\d{4}$/ }" :class="{'input': true, 'is-danger': errors.has('add-brand.mobile') }"></the-mask>
            <input type="password" v-model="brand.password" placeholder="Password" autocomplete="current-password" required />
            <input type="checkbox" v-model="otpConsent" id="alcoholic" class="inline"/>
            <label for="alcoholic" style="margin-top:8px; display:inline;">Opt-in to receiving security authentication
              for your private brand dashboard along with important messages regarding your account and login. By checking
               this box you agree to receive these messages from PICKL.</label>
            <input :disabled="busy || !otpConsent" type="submit" value="Continue" class="btn-lg-green" />
        </form>
    </div>
</template>
<script>
import { TheMask } from 'vue-the-mask'
export default {
  name: 'Register',
  props: {
    errorMessage: String,
    busy: Boolean,
    brandRefer: Boolean,
    accessInvitaion: Object
  },
  components: {
    TheMask
  },
  data () {
    return {
      otpConsent: 0,
      countries: [],
      brand: {
        email: '',
        password: '',
        name: '',
        user_name: '',
        mobile_number: '',
        iso_code: 'US',
        country_code: '+1',
        age_restricted: 0
      }
    }
  },
  methods: {
    onSubmit: function () {
      this.countries.forEach(country => {
        console.log(country.iso_code, this.brand.iso_code)
        if (country.iso_code === this.brand.iso_code) {
          this.brand.country_code = country.isd_code
        }
      })
      this.$emit('submit', this.brand)
    },
    getCountries () {
      this.$http
        .get('countries?service_enabled=true')
        .then(
          (response) => {
            this.countries = response.body.data
          },
          (response) => {
          }
        )
    }
  },
  mounted () {
    this.getCountries()
  },
  watch: {
    accessInvitaion () {
      if (typeof this.accessInvitaion.brand !== 'undefined') {
        this.brand.name = this.accessInvitaion.brand.name
      }
      if (typeof this.accessInvitaion.email !== 'undefined') {
        this.brand.email = this.accessInvitaion.email
      }
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 51px;
  line-height: 72px;
  margin-bottom: 20px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

.country-code-field, .phone-field {
  width:45%;
}

.phone-field {
  float: right;
}
.country-code-field {
  float: left;
  height: 50px;
  border: solid 1px;
  border-radius: 4px;
}
.register-header {
  text-align: center;
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
input:disabled {
  background: gainsboro;
}
</style>
