<template>
    <div class="two-step">
        <h2>Two-factor authentication</h2>
        Verification code has been sent to mobile & email. Enter the code.
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <!-- <div>
                <label class="verification-label">Enter Email Verification Code</label><br/>
                <input type="tel" maxlength="1" v-for="n in 6" :key="n" ref="email-verification-ref" v-on:keydown="handleEmailKeyPress(n - 1, $event)" :autofocus="1 == n" />
            </div> -->
            <div>
                <label class="verification-label">Enter Mobile Verification Code</label><br/>
                <input type="tel" maxlength="1" v-for="n in 6" :key="n" ref="sms-verification-ref" v-on:keydown="handleSmsKeyPress(n - 1, $event)" :autofocus="1 == n" />
            </div>
            <div class="resend-text">Didn't get the verification codes? <a href="javascript:;" @click="sendVerificationCodesAgain" class="purple-link">Send Again</a></div>
            <input type="submit" class="btn-lg-green" value="CONTINUE" />
        </form>
    </div>
</template>

<script>
export default {
  name: 'TwoStep',
  props: {
    errorMessage: String,
    user: Object,
    busy: Boolean
  },
  methods: {
    handleEmailKeyPress (n, e) {
      let twoStep = this.$refs['email-verification-ref']
      let key = e.keyCode
      if (key >= 96 && key <= 105) key = key - 48

      if (key >= 48 && key <= 57) {
        twoStep[n].value = String.fromCharCode(key)
        if (n !== 5) {
          twoStep[n + 1].focus()
        }
      } else if (key === 8) {
        if (twoStep[n].value.length > 0) {
          twoStep[n].value = ''
        } else if (n !== 0) {
          twoStep[n - 1].value = ''
        }

        if (n !== 0) twoStep[n - 1].focus()
      }
      if (key !== 9 && key !== 13) {
        e.preventDefault()
      }
    },

    handleSmsKeyPress (n, e) {
      let twoStep = this.$refs['sms-verification-ref']
      let key = e.keyCode
      if (key >= 96 && key <= 105) key = key - 48

      if (key >= 48 && key <= 57) {
        twoStep[n].value = String.fromCharCode(key)
        if (n !== 5) {
          twoStep[n + 1].focus()
        }
      } else if (key === 8) {
        if (twoStep[n].value.length > 0) {
          twoStep[n].value = ''
        } else if (n !== 0) {
          twoStep[n - 1].value = ''
        }

        if (n !== 0) twoStep[n - 1].focus()
      }
      if (key !== 9 && key !== 13) {
        e.preventDefault()
      }
    },

    onSubmit () {
      let emailCodeArr = this.$refs['email-verification-ref']
      let smsCodeArr = this.$refs['sms-verification-ref']
      let smsCode = ''
      let emailCode = ''
      for (var n = 0; n < 6; ++n) {
        smsCode += smsCodeArr[n].value
      }
      // for (var m = 0; m < 6; ++m) {
      //   emailCode += emailCodeArr[m].value
      // }
      this.$emit('submit', { 'smsCode': smsCode, 'emailCode': emailCode })
    },

    sendVerificationCodesAgain () {
      this.$emit('sendVerificationCodesAgain')
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 54px;
    line-height: 72px;
    margin-bottom: 20px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
}

.two-step {
    font-size: 17px;
    color: rgba(0,0,0,0.7);
}

.two-step input[type=tel] {
    width: 14%;
    margin-right: 3.2%;
    height: 54px;
    font-size: 24px;
    padding: 15px 0;
    text-align: center;
    font-weight: 700;
}

.two-step input[type=tel]:last-child {
    margin-right: 0;
}

.two-step .resend-text {
    font-size: 18px;
    margin: 4px 0;
}

.verification-label {
  margin-top: 40px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .two-step input[type=tel] {
        height: 40px;
        padding: 8px 0;
    }
}
</style>
