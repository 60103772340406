var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outer-box vertical-center-transform" }, [
    _c("div", { staticClass: "posRel" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 login-box" },
          [
            _vm._m(0),
            !_vm.leadCreated && !_vm.accountAlreadyExists
              ? _c("RegisterForm", {
                  attrs: {
                    busy: _vm.busy,
                    errorMessage: _vm.errorMessage,
                    brandRefer: _vm.brandRefer,
                    accessInvitaion: _vm.accessInvitaion
                  },
                  on: { submit: _vm.registerBrand }
                })
              : _vm._e(),
            _vm.accountAlreadyExists && !_vm.leadCreated
              ? _c("Password", {
                  attrs: {
                    busy: _vm.busy,
                    responseDetails: _vm.UserRegisterResponse,
                    errorMessage: _vm.errorMessage
                  },
                  on: { submit: _vm.submitPassword }
                })
              : _vm._e(),
            _vm.accountCreated
              ? _c("div", [
                  _vm._m(1),
                  _c("h3", { staticStyle: { "text-align": "justify" } }, [
                    _vm._v(
                      "Thank you for the interest, One of our representative will be keep in touch with you within 24 hours."
                    )
                  ]),
                  _c("br"),
                  _c("br"),
                  _c(
                    "h4",
                    { staticClass: "pull-right" },
                    [
                      _c("router-link", { attrs: { to: "/brand/login" } }, [
                        _vm._v("Click Here")
                      ]),
                      _vm._v(" to Login")
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.leadCreated && !_vm.accountCreated
              ? _c("EmailMobileVerification", {
                  attrs: {
                    busy: _vm.busy,
                    user: _vm.user,
                    errorMessage: _vm.errorMessage
                  },
                  on: {
                    submit: _vm.verifyEmailMobile,
                    sendVerificationCodesAgain: _vm.sendVerificationCodesAgain
                  }
                })
              : _vm._e(),
            !_vm.accountCreated
              ? _c(
                  "h3",
                  {
                    staticStyle: { "text-align": "left", "font-size": "25px" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn-sm-green",
                        staticStyle: {
                          cursor: "pointer",
                          "text-decoration": "none"
                        },
                        on: { click: _vm.pushToLoginPage }
                      },
                      [_vm._v("Login")]
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._m(2),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 text-center" }, [
      _c("h2", [_vm._v("Welcome to PICKL!")]),
      _c("h4", { staticStyle: { "margin-top": "20px" } }, [
        _vm._v("Hey Brands! Create your free account here:")
      ]),
      _c("h4", [
        _vm._v(
          "Everyone else: Please download PICKL at the Apple/Google App store."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("Welcome To  "),
      _c("span", { staticClass: "green-text" }, [_vm._v("PICKL")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-xs-12 col-md-6 video-out vertical-center-transform" },
      [
        _c(
          "div",
          { staticClass: "video-box", staticStyle: { text: "align:center" } },
          [
            _c("ul", {
              staticStyle: { "margin-left": "-15px", "text-align": "center" }
            }),
            _c("br"),
            _c("img", {
              staticClass: "pickl-logo",
              staticStyle: {
                width: "100%",
                height: "auto",
                margin: "auto",
                display: "block"
              },
              attrs: { src: "/img/brand_new_feature.png" }
            })
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }